import { get } from '@/utils/Request'

export function ProcoinMarket (params) {
    return get('quote/marketDataNoSign.do', params)
}

export function ProcoinArticle (params) {
    return get('article/noticeListWeb.do', params, {wwctradeApi: true})
}

export function ProcoinHomeCropymeNoSign (params) {
    return get('home/cropymeNoSign.do', params, {wwctradeApi: true})
}

export function ArticleDetail (params) {
    return get('article/detail.do', params, {wwctradeApi: true})
}

// export function getBanners (params) {
//     return get('procoin/home/account.do', params, {wwctradeApi: true})
// }

