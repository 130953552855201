import axios from "axios";
import { MessageBox, Message } from "element-ui";

// 请求超时
axios.defaults.timeout = 30000
// 默认接口地址
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
// 响应拦截器即异常处理
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    Message('错误请求')
                    break
                case 401:
                    Message('未授权，请重新登录')
                    break
                case 403:
                    Message('拒绝访问')
                    break
                case 404:
                    Message('请求错误,未找到该资源')
                    break
                case 405:
                    Message('请求方法未允许')
                    break
                case 408:
                    Message('请求超时')
                    break
                case 500:
                    Message('服务器繁忙')
                    break
                case 501:
                    Message('网络未实现')
                    break
                case 502:
                    Message('网络错误')
                    break
                case 503:
                    Message('服务不可用')
                    break
                case 504:
                    Message('网络超时')
                    break
                case 505:
                    Message('http版本不支持该请求')
                    break
                default:
                    Message(`连接错误${error.response.status}`)
            }
        } else {
            Message.error('连接到服务器失败')
        }
        return Promise.resolve(error.response)
    })


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} optios [接口/配置]
 */
export function get (url, params, options) {
    console.log(options)
    if (options && options.wwctradeApi) {
        axios.defaults.baseURL = process.env.VUE_APP_wwctrade_API
    } else {
        axios.defaults.baseURL = process.env.VUE_APP_BASE_API
    }
    console.log(axios.defaults.baseURL)
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params})
            .then(res => {
                resolve(res.data)
            }).catch(err => {
            reject(err.data)
        })
    })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, params, options) {
    console.log(axios.defaults.baseURL)
    return new Promise((resolve, reject) => {
        axios.post(url, params, options)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

